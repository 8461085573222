<template>
      <PageHeader :title="title" :items="items" /> 
      <shop />
      <!-- <tablecustom @search="searchB" :datafilter="filterAll" :columns="columns" :rows="filteredItems" @create="create" @change="change" @clearFilter="clearFilter" @open="open" @removeAll="removeAll"/>
      <template v-if="showModal == true" >
          <viewbox v-if="showModal" @close="showModal = false" :obj="obj"  @remove="remove"></viewbox>
      </template>
      <createProducts v-if="modalCreatedBox" @close="modalCreatedBox = false"></createProducts>  -->
  </template>
  
  <script>
  import PageHeader from "@/components/page-header";
  import shop from '@/components/shop/index'
  // import tablecustom from '@/components/globaltable/index';
  // import createProducts from './createproduct'
  // import { Products, CategoriesProducts } from '@/API.js';
  // import viewbox from './view.vue'
//   import Multiselect from "@vueform/multiselect";
//   import "@vueform/multiselect/themes/default.css";
      
  // let apiServe = new Products();
  // let apiCategory = new CategoriesProducts();
  
  export default {
    components: {
      PageHeader,
      shop,
      // tablecustom,
      // createProducts,
      // viewbox
    //   Multiselect
    },
    data() {
      return {
        title: this.$t('Products'),
        selectcategory: 0,
        selectprovider: 0,
        search: '',
        modalCreatedBox: false,
        items: [
          {
            text: this.$t('Products'),
            href: "/",
          },
          {
            text: this.$t('all'),
            active: true,
          },
        ],
        showModal: false,
        obj: {},
        columns: [
          {
            name: "ID",
            text: "id",
            align: "left",
          },
          {
            name: this.$t("Product"),
            text: "title",
            align: "left",
          },
          {
            name: this.$t("Categories"),
            text: "category",
            align: "left",
          },
          {
            name: this.$t("price"),
            text: "price",
            align: "left",
          }
        ],
        selected: [],
        filterAll: [
          {
              name: "Categories",
              category: "0",
              items: []
          },
          {
              name: "Provider",
              category: "0",
              items: []
          }
        ],
        rows: [],
      };
    },
    created() {
      // this.getdata();
      // ("ds", this)
    },
    methods: {
      // getdata(){
      //   apiServe.getAllProducts().then(result => {
      //     this.rows = result;
      //     let newobj = this.rows
      //     let newArrCategory = Array.from(new Set(newobj.map(item => JSON.stringify(item.category)))).map(item => JSON.parse(item));
      //     let newArrProvider = Array.from(new Set(newobj.map(item => JSON.stringify(item.provider)))).map(item => JSON.parse(item));
      //     this.filterAll[0]['items'] = newArrCategory;
      //     this.filterAll[1]['items'] = newArrProvider;
      //   })
      // },
      create: function(){
        this.modalCreatedBox = true
      },
      open: function(e){
        // apiServe.getProduct(e.id).then(res => {
        //   this.obj = res;
        //   this.showModal = true;
        // })
      },
      searchB(e){
        this.search = e
      },
      change(name, e){
        if(name == 'Categories'){
          this.selectcategory = e;
        } else if(name == 'Provider'){
          this.selectprovider = e;
        }
      },
      // remove: function(e, form){
      //   apiServe.deleteInv(e.id).then(res => {
      //     if(res){
      //       this.showModal = false;
      //       this.getdata();
      //     }
      //   })
      //   this.$toast.success(this.$t('Removed'));
      // },
      // removeAll: function(e){
      //   for(var item in e) {
      //     apiServe.deleteProduct(e[item]).then(res => {
      //       if(res){
      //         this.$toast.success(this.$t('Removed'));
      //         this.getdata();
      //       }
      //     })
      //   }
      // },
    },
    computed: {
      filteredItems: function() {
          return this.rows
              // По полю поиска
              
              .filter(item => {
                  return this.search == '' || item.title.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
              })
  
              // По категории
              .filter(item => {
                  return this.selectcategory == 0 || item.category == this.selectcategory ;
              })
  
              // По поставщику
              .filter(item => {
                  return this.selectprovider == 0 || item.provider == this.selectprovider;
              })
      }, 
      
    },
    mounted() {
      // this.eventBus.on('saveProduct', (status) => {
      //   if(status == true) {
      //     this.getdata();
      //   }
      // })
    //   this.eventBus.on('editINV', (status) => {
    //     if(status == true) {
    //       this.getdata();
    //     }
    //   })
    }
  };
  </script>
  
  <style scoped>
  .tr_etner:hover {
    cursor: pointer;
    background: #f3f3f9;
  }
  </style>
  